




















































































































































































































































import { defineComponent, onMounted, ref, computed, watch, reactive } from '@vue/composition-api'
import {
  endpoints,
  query,
  showError,
  toCamelCase,
  urlPath,
  groupMultiLevel,
  groupTwoLevel,
  mappingStock,
  convertToWidthCharacter,
  frameBusEvent,
  PACKING_RESULT_DES
} from 'utils'
import { useExpansion } from 'hooks'
import { api, framebus, moment } from 'plugins'
import { Assign, PackingResult, MultiLevelProduct, Customer, OrderDetail } from 'typings'
import {
  LoaderComponent,
  MultiLevelList,
  StepButton,
  OrderDialog,
  ItemAssignFromPacking,
  SettingViewDialog,
  AssignToMultipleCustomersDialog,
  DateRange,
  ConfirmAssign
} from 'components'
import { sumBy, orderBy } from 'lodash'

interface Master {
  id: number
  name: string
}

interface SelectedLevel {
  rootIndex: number | null
  firstIndex: number | null
  secondIndex: number | null
}

interface ActionOptions {
  defaultItem: number | null
  defaultVariety: number | null
  defaultSize: number | null
  defaultQuality: number | null
}

interface PackingResultToSeri extends PackingResult {
  assignmentId?: number | null
  assignmentSeriBoxes?: number | 0
  assignmentSeriBoxesUpdated?: number | 0
}

const AssignSeri = defineComponent({
  components: {
    LoaderComponent,
    MultiLevelList,
    StepButton,
    OrderDialog,
    ItemAssignFromPacking,
    SettingViewDialog,
    AssignToMultipleCustomersDialog,
    DateRange,
    ConfirmAssign
  },
  props: {},
  setup(props, { root }) {
    const { $router, $toast, $route, $store } = root
    const SERI = 1
    const auctionDateModal = ref(false)
    const packedDateStartModal = ref(false)
    const packedDateEndModal = ref(false)
    const isConfirmShow = ref(false)
    const isOpenRootPanel = ref(false)
    const assignmentsSeriOfCurrentCustomer = ref<Array<any>>([])
    const openListAssignmentSeri = ref(false)

    const auctionDate = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.aucDate || moment(new Date()).format('YYYY-MM-DD')
    )
    const packedDateStart = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[0]
        : $route.query.pkDateStart || moment(new Date()).format('YYYY-MM-DD')
    )
    const packedDateEnd = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[1]
        : $route.query.pkDateEnd || moment(new Date()).format('YYYY-MM-DD')
    )
    const ItemAssignFromPackingComponentKey = ref(0)

    const dateRange = ref(['', ''])
    const customers = ref<Array<Customer>>([])
    const selectedCustomer = ref(0)
    dateRange.value = [packedDateEnd.value, packedDateStart.value]
    const isShowActionOptions = ref(false)
    const actionOptions = ref<ActionOptions>({
      defaultItem: null,
      defaultVariety: null,
      defaultSize: null,
      defaultQuality: null
    })
    const selectedLevel = reactive<SelectedLevel>({
      rootIndex: null,
      firstIndex: null,
      secondIndex: null
    })
    const packingResults = ref<Array<PackingResultToSeri>>([])
    const filterPackingResults = ref<Array<PackingResult>>([])
    const packingResultListPrevious = ref<Array<PackingResult>>([])
    const packingResultList = ref<Array<MultiLevelProduct>>([])
    const packingProps = ref({})
    const customerCheckAndBoxes = ref<any>({})
    const assignments = ref<Array<Assign>>([])
    const orderDetails = ref<Array<OrderDetail>>([])
    const orderDetailsFilter = ref<Array<OrderDetail>>([])
    const assignSeriPanelState = computed(() => $store.state.assignSeri.assignSeriPanelState)

    const showDialogId = ref(0)
    const keywordsSearch = ref<Array<string>>([])

    const loading = ref(false)
    const isSaving = ref(false)
    const SHOW_ALL = 'SHOW_ALL'
    const ONLY_REMAINING = 'ONLY_REMAINING'
    const filterByItems = ref([
      { name: root.$t('assign_seri.filter.only_remaining') as string, key: ONLY_REMAINING },
      { name: root.$t('assign_seri.filter.show_all') as string, key: SHOW_ALL }
    ])
    const showOnlyRemaining = ref($route.query.showOnlyRemaining || filterByItems.value[0].key)
    const searchInfo = ref('')
    const treeviewLevel = ref(
      Number(
        $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
      ) || 4
    )

    const getSettingLevel = async (): Promise<void> => {
      treeviewLevel.value =
        Number(
          $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
        ) || 4
    }

    const nextAuctionDate = ref('')

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        nextAuctionDate.value = data.data.date
        auctionDate.value = nextAuctionDate.value as string
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const getCustomers = async (): Promise<void> => {
      try {
        const { data } = await api.get(endpoints.CUSTOMERS)
        // Need to convert result from snake_case to camelCase
        customers.value = toCamelCase(data).map((customer: Customer) => {
          // eslint-disable-next-line no-param-reassign
          customer.name = customer.code
            ? `[${customer.code ?? ''}] ${customer.shortName}`
            : customer.shortName
          return customer
        })
        // Add multiple customers
        customers.value.unshift({
          id: 0,
          name: root.$t('assign_seri.multi_customers')
        } as unknown as Customer)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const mappingPackingAssignSeri = () => {
      packingResults.value = packingResults.value.map((packingResult: PackingResultToSeri) => {
        const assignment = assignments.value.find(
          (e) =>
            e.packingResult === packingResult.id &&
            e.orderType.id === SERI &&
            (selectedCustomer.value !== 0 ? e.customer.id === selectedCustomer.value : true)
        )
        // eslint-disable-next-line no-param-reassign
        packingResult.assignmentId = assignment?.id || null
        // eslint-disable-next-line no-param-reassign
        packingResult.assignmentSeriBoxes = assignment?.boxes || 0
        // eslint-disable-next-line no-param-reassign
        packingResult.assignmentSeriBoxesUpdated = packingResult.assignmentSeriBoxes

        return packingResult
      })

      // get assign boxes of packing in selected auction date
      // const getAssignBoxesPacking = (packingId: number) => {
      //   const assignmentsFilter = assignments.value.filter((assignment: Assign) =>
      //     selectedCustomer.value
      //       ? assignment.customer.id === selectedCustomer.value &&
      //         assignment.packingResult === packingId
      //       : assignment.packingResult === packingId
      //   )
      //   return sumBy(assignmentsFilter, 'boxes')
      // }
      orderDetailsFilter.value = selectedCustomer.value
        ? orderDetails.value.filter(
            (orderDetail: OrderDetail) => orderDetail.order.customer.id === selectedCustomer.value
          )
        : orderDetails.value

      const getPropOrderMatchPacking = (packingId: number, prop: string) => {
        const packingDetail = packingResults.value.find((e: PackingResult) => e.id === packingId)
        let orderDetailsMap = selectedCustomer.value
          ? orderDetailsFilter.value.filter(
              (orderDetail: OrderDetail) => orderDetail.order.customer.id === selectedCustomer.value
            )
          : orderDetails.value

        orderDetailsMap = orderDetailsMap.filter(
          (orderDetail: OrderDetail) =>
            orderDetail.item.id === packingDetail?.item.id &&
            orderDetail.variety?.id === packingDetail?.variety?.id &&
            orderDetail.quality?.id === packingDetail?.quality?.id &&
            orderDetail.quantity === packingDetail?.quantity &&
            orderDetail.size?.id === packingDetail?.size?.id
        )
        return sumBy(orderDetailsMap, prop)
      }

      packingResults.value = packingResults.value.map((packingResult: PackingResult) => {
        const boxesAmount = getPropOrderMatchPacking(packingResult.id, 'boxes')
        return {
          ...packingResult,
          packingBoxes: packingResult.boxes,
          orderedBoxes: boxesAmount,
          orderedSpecialStems: boxesAmount
            ? 0
            : getPropOrderMatchPacking(packingResult.id, 'stems'),
          assignBoxes: packingResult.assignedBoxes // getAssignBoxesPacking(packingResult.id)
        }
      })
    }

    const getPackingResultByDate = async (
      pkDateStart: string,
      pkDateEnd: string,
      aucDate: string
    ): Promise<void> => {
      const queryStr = query.buildQuery({
        start_date: pkDateStart,
        end_date: pkDateEnd
      })
      // eslint-disable-next-line no-param-reassign
      const data = await Promise.all([
        api.get(`${endpoints.PACKING_RESULTS}get_packing_result_by_range?${queryStr}`),
        api.get(`${endpoints.ASSIGNMENTS}?auction_date=${aucDate}`),
        api.get(`${endpoints.ORDER_DETAILS}?auction_date=${aucDate}`)
      ])
      const [{ data: packingResultsData }, { data: assignmentsData }, { data: orderData }] = data
      assignments.value = toCamelCase(assignmentsData)
      orderDetails.value = toCamelCase(orderData)
      packingResults.value = toCamelCase(packingResultsData)
      mappingPackingAssignSeri()
    }

    const getDataByDate = async (
      aucDate: string,
      pkDateStart: string,
      pkDateEnd: string
    ): Promise<void> => {
      loading.value = true
      customerCheckAndBoxes.value = {}
      try {
        await getPackingResultByDate(pkDateStart, pkDateEnd, aucDate)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        auctionDateModal.value = false
        packedDateStartModal.value = false
        packedDateEndModal.value = false
        loading.value = false
      }
    }

    const openDialog = (id: number, data: PackingResultToSeri): void => {
      packingResultListPrevious.value = filterPackingResults.value
      if (selectedCustomer.value === 0) {
        showDialogId.value = id
        packingProps.value = data
      }
    }

    const goToDate = () => {
      $router
        .replace({
          name: urlPath.ASSIGN_SERI.name,
          query: {
            aucDate: auctionDate.value,
            pkDateStart: packedDateStart.value,
            pkDateEnd: packedDateEnd.value
          }
        })
        .catch((err) => {
          // Ignore the vuex err regarding navigating to the page they are already on.
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // But print any other errors to the console
            console.log(err)
          }
        })
      const latestSelectedDates = {
        auctionDate: auctionDate.value.toString(),
        packingDateRange: [packedDateStart.value, packedDateEnd.value]
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
      getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)
    }

    const updatePanelState = (mutation: string, panelState: Record<string, unknown>) => {
      $store.commit(mutation, panelState)
    }

    const getChooseDetail = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      const defaultItem = ref<Master | null>(null)
      const defaultVariety = ref<Master | null>(null)
      const defaultSize = ref<Master | null>(null)
      const defaultQuality = ref<Master | null>(null)

      const data: Array<MultiLevelProduct> = packingResultList.value as Array<MultiLevelProduct>

      defaultItem.value = data[rootIndex].children[0].children[0].children[0].item
      if (firstIndex !== null) {
        defaultVariety.value = data[rootIndex].children[firstIndex].children[0].children[0].variety
      }
      if (secondIndex !== null) {
        defaultSize.value =
          data[rootIndex].children[Number(firstIndex)].children[secondIndex].children[0].size

        defaultQuality.value =
          data[rootIndex].children[Number(firstIndex)].children[secondIndex].children[0].quality
      }
      return { defaultItem, defaultVariety, defaultSize, defaultQuality }
    }

    const createAssignAPI = async () => {
      const assignmentsList = packingResults.value
        .filter((e) => e.assignmentSeriBoxes !== e.assignmentSeriBoxesUpdated)
        .map((packingResult) => {
          return {
            id: packingResult.assignmentId,
            packing_result_id: packingResult.id,
            auction_date: auctionDate.value,
            item_id: packingResult.item.id,
            variety_id: packingResult.variety.id,
            size_id: packingResult.size.id,
            color_ids: packingResult.color?.id ? [packingResult.color.id] : [],
            quality_id: packingResult.quality.id,
            unit_id: packingResult.unit.id,
            order_type_id: 1,
            box_type_id: packingResult.boxType.id,
            customer_id: selectedCustomer.value,
            quantity: packingResult.quantity,
            boxes: packingResult.assignmentSeriBoxesUpdated,
            stems: (packingResult.assignmentSeriBoxesUpdated ?? 0) * packingResult.quantity,
            price: packingResult.desiredPrice,
            breakdowns: [],
            amount:
              (packingResult.assignmentSeriBoxesUpdated ?? 0) *
              packingResult.desiredPrice *
              packingResult.quantity
          }
        })

      if (assignmentsList.length) {
        let response
        try {
          isSaving.value = true
          response = await api.post(`${endpoints.ASSIGNMENTS}create_from_packing`, {
            assignments: assignmentsList
          })
          $toast.success(`${response?.data.length} ${root.$t('assign_seri.msg.success')}`)
        } catch (e) {
          showError(e, $toast, root.$t('assign_seri.create_failed') as string)
        } finally {
          isSaving.value = false
          await getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)
        }
      } else {
        $toast.success(`0 ${root.$t('assign_seri.msg.success')}`)
      }
    }

    const saveAssignmentsSeri = async () => {
      const assignmentsList = packingResults.value.filter(
        (e) => e.assignmentSeriBoxes !== e.assignmentSeriBoxesUpdated
      )
      if (
        assignmentsList.filter((e: any) => e.boxes - e.assignBoxes < e.assignmentSeriBoxesUpdated)
          .length > 0
      ) {
        isConfirmShow.value = true
      } else await createAssignAPI()
    }

    const handleClickOk = () => {
      createAssignAPI()
      isConfirmShow.value = false
    }

    const openActionSheet = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      isShowActionOptions.value = true
      selectedLevel.rootIndex = rootIndex
      selectedLevel.firstIndex = firstIndex
      selectedLevel.secondIndex = secondIndex

      const { defaultItem, defaultVariety, defaultSize, defaultQuality } = getChooseDetail(
        rootIndex,
        firstIndex,
        secondIndex
      )
      if (defaultItem.value) {
        actionOptions.value.defaultItem = defaultItem.value.id as number
      }

      if (defaultVariety.value) {
        actionOptions.value.defaultVariety = defaultVariety.value.id as number
      }

      if (defaultSize.value) {
        actionOptions.value.defaultSize = defaultSize.value.id as number
      }

      if (defaultQuality.value) {
        actionOptions.value.defaultQuality = defaultQuality.value.id as number
      }
    }

    const updatePackedDateStart = () => {
      if (packedDateStart.value > packedDateEnd.value) {
        packedDateEnd.value = packedDateStart.value
      }
    }

    const { getExpandPanelState, getCollapsePanelState } = useExpansion()

    const currentDataState = computed(() => {
      // return current state
      return {
        data: packingResultList.value,
        action: 'setAssignSeriPanelState',
        keys: ['itemName', 'varietyName'],
        state: assignSeriPanelState.value
      }
    })

    const expandAll = () => {
      const { data, action, keys, state } = currentDataState.value
      const argument = {
        data,
        keys,
        state,
        selected: selectedLevel
      }

      updatePanelState(action, getExpandPanelState(argument))
    }

    const collapseAll = () => {
      const { data, action, keys, state } = currentDataState.value
      const argument = {
        data,
        keys,
        state,
        selected: selectedLevel
      }

      updatePanelState(action, getCollapsePanelState(argument))
    }

    const handleSearchInput = (value: string) => {
      if (value !== searchInfo.value) {
        searchInfo.value = value
      }
    }

    const setDate = (start: string, end: string) => {
      packedDateStart.value = start
      packedDateEnd.value = end
    }

    const reload = async (enableScrollToPreviousPosition: boolean) => {
      // get position before reload
      const positionY = window.scrollY

      await getSettingLevel()
      await getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)

      // scroll to previous position
      if (enableScrollToPreviousPosition) {
        window.scrollTo(0, positionY)
      }
    }

    const onCheckCustomer = (data: any) => {
      customerCheckAndBoxes.value[showDialogId.value.toString()] = { data }
    }

    const onTyping = (event: any) => {
      if (event.keyCode === 13) {
        if (!keywordsSearch.value.includes(searchInfo.value) && searchInfo.value) {
          keywordsSearch.value.push(searchInfo.value)
        }
        searchInfo.value = ''
        if (keywordsSearch.value.length) {
          isOpenRootPanel.value = true
          setTimeout(() => {
            isOpenRootPanel.value = false
          }, 500)
        }
      }
    }

    const removeKeywordsSearch = (item: string) => {
      keywordsSearch.value.splice(keywordsSearch.value.indexOf(item), 1)
      keywordsSearch.value = [...keywordsSearch.value]
    }

    const openPanelStateAfterSearch = () => {
      const newState = assignSeriPanelState.value
      newState.panels = []
      for (let i = 0; i < packingResultList.value.length; i += 1) {
        newState.panels.push(i)
      }
      updatePanelState('setAssignSeriPanelState', newState as any)
    }
    const closeAllPanel = () => {
      const newState = assignSeriPanelState.value
      newState.panels = []
      updatePanelState('setAssignSeriPanelState', newState as any)
    }
    watch(
      () => [packingResults.value, keywordsSearch.value, showOnlyRemaining.value],
      () => {
        if (keywordsSearch.value.length) {
          const filterPackingResultsStartsWith = packingResults.value.filter(
            (packingResult: PackingResult) => {
              return keywordsSearch.value.some((searchStr: string) => {
                const text = convertToWidthCharacter(searchStr.toLowerCase().trim(), 'full')
                const regex = new RegExp(`^${text}|\\|${text}`)
                return regex.test(packingResult.searchStr.toLowerCase())
              })
            }
          )

          const filterPackingResultsIncludes = packingResults.value.filter(
            (packingResult: PackingResult) => {
              const searchStrPackingResult = packingResult.searchStr.toLowerCase()
              const isExistInFilterPackingResultsStartsWith = filterPackingResultsStartsWith.find(
                (filteredPackingResult: PackingResult) =>
                  filteredPackingResult.id === packingResult.id
              )
              if (isExistInFilterPackingResultsStartsWith) {
                return false
              }
              return keywordsSearch.value.some((searchStr: string) => {
                const text = convertToWidthCharacter(searchStr.toLowerCase().trim(), 'full')
                return searchStrPackingResult.includes(text)
              })
            }
          )

          filterPackingResults.value = [
            ...filterPackingResultsStartsWith,
            ...filterPackingResultsIncludes
          ]
        } else {
          filterPackingResults.value = packingResults.value
        }

        if (packingResultListPrevious.value.length) {
          filterPackingResults.value = filterPackingResults.value.filter(
            (packing: PackingResult) => {
              return packingResultListPrevious.value.some((packingPrev: PackingResult) => {
                return packingPrev.id === packing.id
              })
            }
          )
          packingResultListPrevious.value = []
        } else if (showOnlyRemaining.value === ONLY_REMAINING) {
          filterPackingResults.value = filterPackingResults.value.filter(
            (packing: any) => packing.packingBoxes !== packing.assignBoxes
          )
        }

        const groupPackingResults =
          treeviewLevel.value === 2
            ? groupTwoLevel(
                filterPackingResults.value,
                ['packingBoxes', 'assignBoxes', 'orderedSpecialStems'],
                ['itemName']
              )
            : groupMultiLevel(
                filterPackingResults.value,
                ['packingBoxes', 'assignBoxes', 'orderedSpecialStems'],
                ['itemName', 'varietyName', 'qualitySizeName']
              )
        packingResultList.value = mappingStock(
          groupPackingResults,
          {
            orderDetails: orderDetailsFilter.value
          },
          treeviewLevel.value === 2,
          PACKING_RESULT_DES
        ) as Array<MultiLevelProduct>
        ItemAssignFromPackingComponentKey.value += 1
        if (isOpenRootPanel.value) {
          openPanelStateAfterSearch()
        }
        // else {
        //   closeAllPanel()
        // }
      }
    )
    watch(
      () => [selectedCustomer.value, assignments.value],
      () => {
        assignmentsSeriOfCurrentCustomer.value = orderBy(
          assignments.value.filter(
            (assignment: any) =>
              assignment.customer.id === selectedCustomer.value && assignment.orderType.id === SERI
          ),
          'id',
          'asc'
        )
      }
    )

    onMounted(async () => {
      await getSettingLevel()
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        )
      )
        await getNextAuctionDate()
      await getCustomers()
      await getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)
    })

    return {
      dateRange,
      auctionDate,
      packedDateStart,
      packedDateEnd,
      auctionDateModal,
      packedDateStartModal,
      packedDateEndModal,
      customers,
      selectedCustomer,
      goToDate,
      packingResults,
      loading,
      isSaving,
      packingResultList,
      actionOptions,
      assignSeriPanelState,
      updatePanelState,
      openActionSheet,
      updatePackedDateStart,
      expandAll,
      collapseAll,
      saveAssignmentsSeri,
      isShowActionOptions,
      handleSearchInput,
      searchInfo,
      showDialogId,
      openDialog,
      reload,
      mappingPackingAssignSeri,
      packingProps,
      assignments,
      onCheckCustomer,
      customerCheckAndBoxes,
      setDate,
      treeviewLevel,
      isConfirmShow,
      handleClickOk,
      showOnlyRemaining,
      filterByItems,
      ItemAssignFromPackingComponentKey,
      keywordsSearch,
      onTyping,
      removeKeywordsSearch,
      assignmentsSeriOfCurrentCustomer,
      openListAssignmentSeri
    }
  }
})

export default AssignSeri
